<template>
  <div class="profile">
    <h1 large class="display-3 text-center pa-8" center>My Profile</h1>
    


    <div v-if="loggedInUser && loggedInUser.email != $auth.user.email">
    <v-card
      class="mx-auto"
      max-width="800px"
    >
      <v-card-text>
        <p class="headline text--primary">
          Complete Profile
        </p>
        <div>It looks like you need to finish setting up your profile. Please enter the following information to complete your set up.</div>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="createNewUser()"
        >
          <v-row>
            <v-col cols="12" sm="4">
              <v-text-field v-model="$auth.user.email" :rules="emailRules" label="Email" id="newUserEmail" disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="First Name" id="firstName" v-model="newUser.firstName" required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="Last Name" id="lastName"
              v-model="newUser.lastName" required
              ></v-text-field>
            </v-col>
          </v-row>
          <p class="headline text--primary">
            Select Group to Request Access to
          </p>
          <v-list>
            <v-subheader v-for="group in allGroups" :key="group.id" @click.capture.stop="toggleNewGroup(group)">
                <v-checkbox v-model="selectedGroups" multiple  :value="group" :label="group.name"></v-checkbox>
            </v-subheader>
          </v-list>
          <v-btn
            type="submit"
            :disabled="!valid"
            class="mr-4"
            color="primary"
            small
            @click="newUserRequest = true"
          >
            Save
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="newUserRequest"
      top
      :timeout="3000"
    >
      Your information has been saved
      <v-btn
        color="red"
        text
        @click="newUserRequest = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>



<div v-if="loggedInUser && loggedInUser.email == $auth.user.email">
    <v-card
      class="mx-auto"
      max-width="800px"
    >
      <v-card-text>
        <div v-if="loggedInUser.active === false" >
        Your account has not yet been activated. Please contact your group admin for full access to live feeds and alerts. <br><br>
        </div>
        <div>Welcome</div>
        <p class="headline text--primary">
          {{loggedInUser.firstName}} {{loggedInUser.lastName}}
        </p>
        <div float="right">
          <img :src="$auth.user.picture" width=100>
          <h2>{{ $auth.user.email }}</h2>
        </div>
          <div>
              <v-btn color="#000e29" class="white--text" v-if="$auth.isAuthenticated" @click="logout">Log out</v-btn>
          </div>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="saveChanges()"
        >
          <v-row>
            <!--<v-col cols="12" sm="4">
              <v-text-field v-model="loggedInUser.email" :rules="emailRules" label="Email" required :id="`${loggedInUser.id}-email`"
              ></v-text-field>
            </v-col>-->
            <v-col cols="12" sm="4">
              <v-text-field  v-model="loggedInUser.firstName" label="First Name" required :id="`${loggedInUser.id}-fName`"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field v-model="loggedInUser.lastName" label="Last Name" required :id="`${loggedInUser.id}-lName`"
              ></v-text-field>
            </v-col>
          </v-row>
           

          <p class="headline text--primary">
            Edit Notifications
          </p>
          <v-list>
            <v-subheader v-for="(group) in loggedInUser.groups" :key="group.id" @click.capture.stop="toggleGroup(group)">
                <v-checkbox color="#fe6c29" v-model="selected" multiple :value="group" :label="group.name"></v-checkbox>
            </v-subheader>
          </v-list>
          <v-btn
            type="submit"
            :disabled="!valid"
            class="mr-4 white--text"
            color="#000e29"
            small
            @click="saveUser = true"
          >
            Save
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="saveUser"
      top
      :timeout="3000"
    >
      Your information has been saved
      <v-btn
        color="red"
        text
        @click="saveUser = false"
      >
        Close
      </v-btn>
    </v-snackbar>
</div>
    <!--Auth0 user info
    <div>
    <div>
      <img :src="$auth.user.picture" width=100>
      <h2>{{ $auth.user.name }}</h2>
      <p>{{ $auth.user.email }}</p>
    </div>

    <div>
      <pre>{{ JSON.stringify($auth.user, null, 2) }}</pre>
    </div>
  </div>-->

  </div>
</template>

<script>
// @ is an alias to /src
import {HTTP} from '@/api/http-common'
export default {
  data() {
    return {
      saveUser: false,
      newUserRequest: false,
      newUser: {},
      user:[
        {
          id: 0,
          firstName: "Trevor",
          lastName: "Allen",
          email: "trevor@woodlandhills.com",
          groups: [
            {
              id: 0,
              name: "Woodland Hills",
              notification: true
            },
            {
              id: 1,
              name: "Provo",
              notification: true
            }
          ]
        }
      ],
      loggedInUser: [],
      allGroups: [],
      availableGroups: [],
      selectedGroups: [],
      valid: true,
      selected: [],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
    }
  },
  created () {
      this.getLoggedInUser()
      this.getAllGroups()
  },
  methods: {
    getLoggedInUser: function(){
      //console.log('LOOK HERE: ' + this.$auth.user.email);
        if (this.$auth.user.email) {
          let apiResponse = HTTP.get('user/' + this.$auth.user.email)
          return apiResponse.then(response => {
          this.loggedInUser = response.data
          //console.log("loggedInUser: " + this.loggedInUser)
          let selectedFromStart = []
          let availGroups = []
          if(this.loggedInUser.groups.length > 0){
            this.loggedInUser.groups.forEach(function(group){
              availGroups.push(group)
              if(group.notification === 1){
                selectedFromStart.push(group)
              }
            })
          }
          this.selected = selectedFromStart
          this.availableGroups = availGroups
          //console.log('Get logged in user: ', JSON.stringify(this.loggedInUser))
        }).catch(error => {
          console.log(error)
          this.loggedInUser = []
          //console.log(this.loggedInUser.lastName)
        })  
      } else return
        
    },
    saveChanges: function(){
      this.selected.forEach(location => console.log(location.id));
      //console.log(this.selected)
      //let value = this.loggedInUser.email
      //console.log('save the email to be', JSON.stringify(this.loggedInUser))
      //console.log(JSON.stringify(this.selected))
      let selected = this.selected
      this.loggedInUser.groups.forEach(function(startingGroup){
        if(!selected.includes(startingGroup)){
          startingGroup.notification = 0
        }
        else{
          startingGroup.notification = 1
        }
      })
      //console.log("Hello?",JSON.stringify(this.loggedInUser))
      HTTP.put('/user/' + this.$auth.user.email, {
        "id": this.loggedInUser.id,
        "email": this.loggedInUser.email,
        "firstName": this.loggedInUser.firstName,
        "lastName": this.loggedInUser.lastName,
        "groups": this.loggedInUser.groups
      }).catch(function (error) {
        console.log(error);
      });
    },
    createNewUser: function(){
      if(this.newUser.firstName == null || this.newUser.lastName == null || this.$auth.user.email == null || this.personExists(this.$auth.user.email) || this.selectedGroups.length == 0 ){
        //console.log("ERROR", this.newUser.firstName, this.newUser.lastName, this.$auth.user.email)
        alert("Form not complete")
        return
        
      }

      let newUser = this.newUser
      newUser.groups = []
      newUser.groups = this.selectedGroups
      HTTP.post('user/admin', {
        "firstName": newUser.firstName,
        "lastName": newUser.lastName,
        "groups": newUser.groups,
        "email": this.$auth.user.email,
        "password": ""
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
      this.newUser = {}
      this.selectedGroups = []
    },
    toggleGroup (group) {
      if (this.selected.includes(group)) {
        // Removing the group
        this.selected.splice(this.selected.indexOf(group), 1);
      } else {
        this.selected.push(group);
      }
    },

    toggleNewGroup (group) {
      if (this.selectedGroups.includes(group)) {
        // Removing the group
        this.selectedGroups.splice(this.selectedGroups.indexOf(group), 1);
      } else {
        this.selectedGroups.push(group);
      }
    },

    getAllGroups: function(){
      return HTTP.get('user/edwinnotification1@gmail.com').then(response => {
        this.allGroups = response.data.groups
        //console.log(this.allGroups)
        }
      )
    },
    personExists(email){
      HTTP.get("user/" + email)
      .then(function() {
        //console.log("Person already in DB. Continue")
        return true
      })
      .catch(function() {
        //console.log("Person not in DB. Continue")
        return false
      })
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }

  }
  

}
</script>
