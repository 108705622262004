<template>
  <div class="team">
    <h1 large class="display-3 text-center pa-8" center>Group and User Management</h1>

    <div v-if="finalUsers && loggedInUser && (loggedInUser.email == $auth.user.email)">
      <p></p>
      <v-layout align-center justify-center row fill-height>
        <v-expansion-panels accordion hover multiple style="maxWidth: 1000px;">
          <div class="headline mb-1"> Active Users
          </div>
          <v-expansion-panel v-for="user in finalUsers" :key="user.id">
            <v-expansion-panel-header disable-icon-rotate>
              <template v-slot:actions>
                <v-icon color="#fe6c29">account_circle</v-icon>
              </template>
              {{user.firstName}} {{user.lastName}}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="grey--text">
              <v-form ref="form" v-model="valid" lazy-validation
                @submit.prevent="saveChanges(user.email, user.id, user.firstName, user.lastName, user.selectedGroups), saveUser = true, saveUserName = user.firstName + ' ' + user.lastName"
              >
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field v-model="user.email" :rules="emailRules" label="Email" required :id="`${user.id}-email`"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field  v-model="user.firstName" label="First Name" required :id="`${user.id}-fName`"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field v-model="user.lastName" label="Last Name" required :id="`${user.id}-lName`"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-list>
                    <span class="headline">Groups</span>
                    <v-subheader v-for="(group) in loggedInUser.groups" :key="group.id">
                      <v-checkbox color="#fe6c29" v-model="user.selectedGroups" multiple :value="group" :label="group.name"></v-checkbox>
                    </v-subheader>
                  </v-list>
                </v-row>
                <v-btn type="submit" :disabled="!valid" class="mr-4 white--text" color="#000e29" small
                >
                  Save
                </v-btn>
                <!-- <v-btn class="mr-4" color="amber darken-2" small dark
                  @click="resetPassword(user.id), resetPass = true, resetPassUser = user.firstName + ' ' + user.lastName"
                >
                  Reset Password
                </v-btn> -->
                <v-btn class="mr-4" color="#fe6c29" small dark @click.stop="deleteDialog = true"
                >
                  Delete User
                </v-btn>
                <v-dialog v-model="deleteDialog" max-width="290"
                >
                  <v-card>
                    <v-card-title class="headline">Confirm Deletion</v-card-title>
                    <v-card-text>
                      Are you sure you want to delete this user? This cannot be undone.
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="deleteDialog = false"
                      >
                        No
                      </v-btn>
                      <v-btn color="green darken-1" text
                        @click="deleteUser(user.id), deletedUser = true, deletedUsername = user.firstName + ' ' + user.lastName, deleteDialog = false"
                      >
                        Yes
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-layout>
    </div>
    
    <div v-if="finalUserRequests && loggedInUser && (loggedInUser.email == $auth.user.email)">
      <p></p>
      <v-layout align-center justify-center row fill-height>
        <v-expansion-panels accordion hover multiple style="maxWidth: 1000px;">
          <div class="headline mb-1"> Activation Requests
          </div>
          <v-expansion-panel v-for="user in finalUserRequests" :key="user.id">
            <v-expansion-panel-header disable-icon-rotate>
              <template v-slot:actions>
                <v-icon color="#fe6c29">account_circle</v-icon>
              </template>
              {{user.firstName}} {{user.lastName}}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="grey--text">
              <v-form ref="form" v-model="valid" lazy-validation
                @submit.prevent="saveChanges(user.email, user.id, user.firstName, user.lastName, user.selectedGroups), saveUser = true, saveUserName = user.firstName + ' ' + user.lastName"
              >
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field v-model="user.email" :rules="emailRules" label="Email" required disabled :id="`${user.id}-email`"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field  v-model="user.firstName" label="First Name" required :id="`${user.id}-fName`"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field v-model="user.lastName" label="Last Name" required :id="`${user.id}-lName`"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-list>
                    <span class="headline">Groups</span>
                    <v-subheader v-for="(group) in loggedInUser.groups" :key="group.id">
                      <v-checkbox v-model="user.selectedGroups" color= "#fe6c29" multiple :value="group" :label="group.name"></v-checkbox>
                    </v-subheader>
                  </v-list>
                </v-row>
                <v-btn type="submit" color="#000e29" :disabled="!valid" class="mr-4 white--text" small
                >
                  Accept
                </v-btn>
                <!-- <v-btn class="mr-4" color="amber darken-2" small dark
                  @click="resetPassword(user.id), resetPass = true, resetPassUser = user.firstName + ' ' + user.lastName"
                >
                  Reset Password
                </v-btn> -->
                <v-btn class="mr-4" color="#fe6c29" small dark @click.stop="deleteDialog = true"
                >
                  Reject
                </v-btn>
                <v-dialog v-model="deleteDialog" max-width="290"
                >
                  <v-card>
                    <v-card-title class="headline">Confirm Deletion</v-card-title>
                    <v-card-text>
                      Are you sure you want to delete this user? This cannot be undone.
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="green darken-1" text @click="deleteDialog = false"
                      >
                        No
                      </v-btn>
                      <v-btn color="green darken-1" text
                        @click="deleteUser(user.id), deletedUser = true, deletedUsername = user.firstName + ' ' + user.lastName, deleteDialog = false"
                      >
                        Yes
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-layout>
    </div>
<p></p>
<div v-if="loggedInUser && (loggedInUser.email == $auth.user.email) && (loggedInUser.role > 1)">
    <v-layout justify-center>
      <!-- <v-row> -->
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn color="#000e29" dark v-on="on" @click="getGroups(loggedInUser)">Create a New User</v-btn>
            <br>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">New User</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="First name*" id="firstName" v-model="newUser.firstName" required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="Last name*" id="lastName" v-model="newUser.lastName" required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="Email*" id="email" v-model="newUser.email" required></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="6" md="6">
                    <v-text-field label="Password*" id="password" v-model="newUser.password" type="password" required></v-text-field>
                  </v-col> -->
                  <v-col cols="12" sm="6" md="6" v-if="selectableGroups">
                    <span class="headline">Groups*</span>
                    <v-subheader v-for="group in selectableGroups" :key="group.id" @click.capture.stop="toggleGroup(group)">
                      <v-checkbox v-model="selectedGroups" multiple :value="group" :label="group.name" required></v-checkbox>
                    </v-subheader>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#fe6c29" text @click="dialog = false">Close</v-btn>
              <v-btn color="#000e29" text @click="dialog = false, createNewUser()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <!-- </v-row> -->
    </v-layout>
  </div>
    <v-snackbar v-model="deletedUser" top :timeout="7000"
    >
      {{deletedUsername}} has been deleted
      <v-btn color="red" text @click="deletedUser = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-snackbar v-model="resetPass" top :timeout="7000"
    >
      Password has been reset for {{resetPassUser}}
      <v-btn color="red" text @click="resetPass = false"
      >
        Close
      </v-btn>
    </v-snackbar>
    <v-snackbar v-model="saveUser" top :timeout="7000"
    >
      {{saveUserName}} has been updated
      <v-btn color="red" text @click="saveUser = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
// @ is an alias to /src
import {HTTP} from '@/api/http-common';

export default {
  data() {
    return {
      dialog: false,
      deleteDialog: false,
      valid: true,
      resetPass: false,
      saveUser: false,
      saveUserName: '',
      resetPassUser: '',
      deletedUser: '',
      deletedUsername: '',
      loggedInUser: [],
      selected: [],
      selectableGroups: [],
      selectedGroups: [],
      finalUsers: [],
      finalUserRequests: [],
      newUser: {},
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      cameras: [],
      users: [],
      camErrors: [],
      userErrors: [],
      sampleUser: [
        {
          id: 1234
        }
      ]
    }
  },
  created() {
    this.getLoggedInUser().then(() =>{
      let users = []
      if(this.loggedInUser.role == 2){
        if(this.loggedInUser.groups.length >= 1){
          this.loggedInUser.groups.forEach(function(group){
            HTTP.get(`user/admin/grouplist/${group.id}`).then(response => {
              response.data.forEach(function(indv){
                //console.log("GROUP ID",indv.groups.name)
                if(!(users.some(user => user.id === indv.id))){
                  //Not in the array, lets add them
                  indv.selectedGroups = []
                  indv.startingGroups = []
                  indv.selectedGroups.push(indv.groups)
                  indv.startingGroups.push(indv.groups)
                  users.push(indv)
                }
                else {
                  //You are in the array, lets add a group to you
                  users.forEach(function(user){
                    if(user.id === indv.id && user.selectedGroups.id !== indv.groups.id){
                      if(indv.groups.notification === 1){
                        user.selectedGroups.push(indv.groups)
                        user.startingGroups.push(indv.groups)
                      }
                      
                    }
                  })
                }
              })
            })
          })
        }        
      } else if(this.loggedInUser.role >= 3 ){
          this.loggedInUser.groups.forEach(function(group){
              HTTP.get(`user/admin/grouplist/${group.id}`).then(response => {
                response.data.forEach(function(indv){
                  //console.log("GROUP ID",indv.groups.name)
                  if(!(users.some(user => user.id === indv.id))){
                    //Not in the array, lets add them
                    indv.selectedGroups = []
                    indv.startingGroups = []
                    indv.selectedGroups.push(indv.groups)
                    indv.startingGroups.push(indv.groups)
                    users.push(indv)
                  }
                  else {
                    //You are in the array, lets add a group to you
                    users.forEach(function(user){
                      if(user.id === indv.id && user.selectedGroups.id !== indv.groups.id){
                        if(indv.groups.notification === 1){
                          user.selectedGroups.push(indv.groups)
                          user.startingGroups.push(indv.groups)
                        }
                        
                      }
                    })
                  }
                })
              })
            })
        }


      this.finalUsers = users

      let userRequests = []
      if(this.loggedInUser.role == 2){
        if(this.loggedInUser.groups.length >= 1){
          this.loggedInUser.groups.forEach(function(group){
            HTTP.get(`user/admin/requestlist/${group.id}`).then(response => {
              response.data.forEach(function(indv){
                //console.log("GROUP ID",indv.groups.name)
                if(!(userRequests.some(user => user.id === indv.id))){
                  //Not in the array, lets add them
                  indv.selectedGroups = []
                  indv.startingGroups = []
                  indv.selectedGroups.push(indv.groups)
                  indv.startingGroups.push(indv.groups)
                  userRequests.push(indv)
                }
                else {
                  //You are in the array, lets add a group to you
                  userRequests.forEach(function(user){
                    if(user.id === indv.id && user.selectedGroups.id !== indv.groups.id){
                      if(indv.groups.notification === 1){
                        user.selectedGroups.push(indv.groups)
                        user.startingGroups.push(indv.groups)
                      }
                      
                    }
                  })
                }
              })
            })
          })
        }        
      } else if(this.loggedInUser.role >= 3 ){
          this.loggedInUser.groups.forEach(function(group){
              HTTP.get(`user/admin/requestlist/${group.id}`).then(response => {
                response.data.forEach(function(indv){
                  //console.log("GROUP ID",indv.groups.name)
                  if(!(userRequests.some(user => user.id === indv.id))){
                    //Not in the array, lets add them
                    indv.selectedGroups = []
                    indv.startingGroups = []
                    indv.selectedGroups.push(indv.groups)
                    indv.startingGroups.push(indv.groups)
                    userRequests.push(indv)
                  }
                  else {
                    //You are in the array, lets add a group to you
                    userRequests.forEach(function(user){
                      if(user.id === indv.id && user.selectedGroups.id !== indv.groups.id){
                        if(indv.groups.notification === 1){
                          user.selectedGroups.push(indv.groups)
                          user.startingGroups.push(indv.groups)
                        }
                        
                      }
                    })
                  }
                })
              })
            })
        }


      this.finalUserRequests = userRequests


    }).catch(e => {
      console.log(e)
    });
  },
  mounted() {},
  methods: {
    getLoggedInUser: function(){
      if (this.$auth.user.email){
        return HTTP.get('user/' + this.$auth.user.email).then(response => {
        if (response.data.active === true) {
          this.loggedInUser = response.data
        }
      })
      } else return
    },
  //Set the camera type based on the status of the cameras. This 'type' is used for coloring 
    saveChanges: function(email, id, fName, lName, selectedGroups){
      if(selectedGroups.length >= 1){
        selectedGroups.forEach(function(group){
          group.notification = 1
        })
      }
      //console.log("NEW SELECTED GROUPS",JSON.stringify(selectedGroups))
      HTTP.put('/user/admin', {
        "id": id,
        "firstName": fName,
        "lastName": lName,
        "email": email,
        "groups": selectedGroups,
        "active": true,
      }).catch(function (error) {
        console.log(error);
      });
    },
    resetPassword: function(id){
      console.log('reset the password for user with id of ', id)
    },
    deleteUser: function(id){
      HTTP.delete('/user/admin', {
        params: {
          userId:id
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    getGroups: function(user){
      this.selectableGroups = user.groups
    },
    toggleGroup: function (group) {
      if (this.selectedGroups.includes(group)) {
        // Removing the group
        this.selectedGroups.splice(this.selectedGroups.indexOf(group), 1);
      } else {
        this.selectedGroups.push(group);
      }
    },
    createNewUser: function(){
      if(this.newUser.firstName == null || this.newUser.lastName == null || this.newUser.email == null){
        //console.log("ERROR", this.newUser.firstName, this.newUser.lastName, this.newUser.email)
        return;
      }

      let newUser = this.newUser
      newUser.groups = []
      newUser.groups = this.selectedGroups
      HTTP.post('user/admin', {
        "firstName": newUser.firstName,
        "lastName": newUser.lastName,
        "groups": newUser.groups,
        "email": newUser.email,
        "password": 'password'
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
      this.newUser = {}
      this.selectedGroups = []
    }
  }
}
</script>
