import axios from 'axios';

let baseIP = process.env.VUE_APP_API_BASE_URL;
let jsonIP = process.env.VUE_APP_CHART_BASE_URL;

export const HTTP = axios.create({
	baseURL: baseIP,
	//headers: {'Access-Control-Allow-Origin': '*',}
});
export const HTTPJSON = axios.create({
	baseURL: jsonIP,
	//headers: {'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*',}
})