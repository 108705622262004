<template>
  <div>
    <h1 class="display-3 text-center pa-8"> Alert {{$route.query.id}} </h1>
    <img src="@/assets/EDWIN_logo_2.png" style="max-width: 300px" class="center">

    <v-layout align-center justify-center row fill-height>
      <v-container>
      <v-card max-width="1500" class="mx-auto">
      <div style="padding: 20px" align="center">
        <h2>{{camera.name}}</h2>
        camid: {{alert.cameraId}}
        <br>{{formatDate(alert.createdAt)}}
        <h4>{{alert.alertType}}</h4>
        <h4 style="color:red">{{alert.message}}</h4>
        Status: {{alert.alertStatus}}
      </div>
    </v-card>
  </v-container>
</v-layout>

<v-layout align-center justify-center row fill-height>
    <v-container>
    <v-card max-width="2000" class="mx-auto">
    <v-row align-center justify-center>
      <v-col class = "player" v-for="stream in streams" :key="stream.url">
        <videoPlayer :options="stream">
        </videoPlayer>
      </v-col>
    </v-row>


  

  <v-row align-center justify-center>
    <ul>
        <li v-for="filepath in alert.filepath" :key=filepath.filename><a :href=filepath.path target=blank>{{filepath.filename}}</a><br><img :src=filepath.path></li>
        <li v-if="alert.videoClip"><a :href=alert.videoClip target=blank>Download video clip</a></li>
    </ul>
  </v-row>
    </v-card>
  </v-container>
    
  
    </v-layout>
    <v-snackbar
      v-model="dismissedAlert"
      top
      :timeout="3000"
    >
      The alert has been dismissed
      <v-btn
        color="red"
        text
        @click="dismissedAlert = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  
</div>  
</template>

<script>
// @ is an alias to /src
import {HTTP} from '@/api/http-common';
import {videoPlayer} from 'vue-videojs7'
//import moment from 'vue-moment'

export default {
  data() {
    return{
      alert: [],
      camera: [],
      streams: [],
      playerOptions: {
        // videojs and plugin options
        height: '360',
        autoplay: false,
        sources: {
          type: "application/x-mpegURL",
          src: "http://69.162.231.249:4040/stream/d471ec7b-771e-418c-87ab-cb1c003f5ad0/index.m3u8"
        }
      },
      generalURL: process.env.VUE_APP_PROXY_BASE_URL,
      dismissedAlert: false,
      dismissedAlertId: '',
      selected: [],
      loggedInUser: []
    }
  },
  created() {
    console.log(this.$route.query.id)
    
    this.getAlertInfo().then(() => {
      let images = this.alert.filepath
      let pathdict = []
      images = images.replace('[','').replace(']','').replaceAll(' ','').replaceAll("'","").split(',')
      for (let index = 0; index < images.length; index++) {
        let fname = images[index].split("/")
        fname = fname[fname.length-1]
        pathdict.push({"filename": fname, "path": process.env.VUE_APP_CHART_BASE_URL + "/" + images[index]})
      }
      this.alert.filepath = pathdict
      console.log(this.alert.filepath)
      let clip = this.alert.videoClip
      this.alert.videoClip = process.env.VUE_APP_CHART_BASE_URL + "/" + clip
      this.alert.cameraId = Number(this.alert.cameraId)
    }).then(() => {
      this.getCameraInfo(this.alert.cameraId).then(() => {
        for (let index=0; index < this.camera.streams.length; index++){
          let streamObj = {
              height: '360',
              autoplay: false,
              sources: {
                type: "application/x-mpegURL",
                src: process.env.VUE_APP_PROXY_BASE_URL + "/" + this.camera.streams[index].url 
              }
            }

          this.streams.push(streamObj)
        }
      })
    })
  },
  mounted() {

  },
  methods: {
    getLoggedInUser: function(){
      if (this.$auth.user.email) {
        return HTTP.get('user/' + this.$auth.user.email).then(response => {
        if (response.data.active === true){
          this.loggedInUser = response.data
          }
        }) 
      }else return
    },
    getAlertInfo: function(){
      return HTTP.get('/alert/' + this.$route.query.id).then(response => {
        this.alert = response.data
        //console.log(this.alert)
        if (this.alert){
          console.log(this.alert)

          
        }            
      }).catch(error => {
        console.log(error);
      })
    },
    getCameraInfo: function(camid){
      return HTTP.get('/camera/' + camid).then(response => {
        this.camera = response.data
        //console.log(this.camera)
        if (this.camera){
          console.log(this.camera)
          //console.log("Filepaths: ", camera.alerts[alertindex].filepath)
           
      }
    }).catch(error => {
      console.log(error)
    })
    },
    getLoggedInUserCameras: function(){
      return HTTP.get('/camera/list/' + this.$auth.user.email).then(response => {
        this.loggedInUserCameras = response.data
        this.loggedInUserCameras.forEach(function(camera){
            
            //console.log("Image filepath: ", camera.alerts.length)
            var alertindex = 0
            while (alertindex < camera.alerts.length){
              var images = camera.alerts[alertindex].filepath.split(",")
              var pathdict = []
              var pathindex = 0;
              while (pathindex < images.length) {
                var pathstring = process.env.VUE_APP_CHART_BASE_URL + "/" + images[pathindex].replace('[','').replace(']','' ).replace(' ','' ).replace("'","" ).slice(0,-1)
                  var fname = pathstring.split("/")
                  fname = fname[fname.length -1]
                pathdict.push({"filename": fname, "path": pathstring}) 
                pathindex++;
              }
              camera.alerts[alertindex].filepath = pathdict
              //console.log("Filepaths: ", camera.alerts[alertindex].filepath)
              alertindex++;
            }
                      
        })
        //console.log('Get cameras for logged in user: ', JSON.stringify(this.loggedInUserCameras))
      })
    },
    getActiveAlerts: function(alerts, camera) {
      let counter = 0;
      alerts.forEach(function(alert){
        if(alert.alert_status !== 'dismissed'){
          counter++;
          HTTP.put('/camera', {
            "id": camera.id,
            "status": "alert"
          }).catch(function (error) {
            console.log(error);
          });
          //camera.status = 'alert'
        }
        if(alert.alert_type === 'High Temperature'){
          alert.color = 'yellow darken-3'
        }
        else if(alert.alert_type === 'AI Analysis'){
          alert.color = 'orange darken-3'
        }
        else if(alert.alert_type === 'Danger'){
          alert.color = 'red darken-2'
        }
        else{
          alert.color = '#2196F3'
        }
      })
      if(counter === 0){
        camera.status = 'good';
        HTTP.put('/camera', {
          "id": camera.id,
          "status": "good",
        }).catch(function (error) {
          console.log(error);
        });
      }
      return counter;
    },
    getAlertCount (alert) {
      if (this.selected.includes(alert)) {
        // Removing the alert
        this.selected.splice(this.selected.indexOf(alert), 1);
      } else {
        this.selected.push(alert);
      }
    },
    dismissAlert (alertId, cameraId, alertType) {
      //console.log('Alert to dismiss: ', alertId, " on camera ", cameraId, " with alert type of ", alertType)
      HTTP.put('/alert', {
        "id": alertId,
        "cameraId": cameraId,
        "status": "dismissed",
        "alertType": alertType

      }).then(response => {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    shapeAlertImagepaths (paths) {
      var images = paths.split(",");
      var index = 0;
      while (index < images.length) {
        images[index] = process.env.VUE_APP_CHART_BASE_URL + "/" + images[index].replace('[','').replace(']','' ).replace(' ','' ).replace("'","" ).slice(0,-1)
        index++;
      }
      return `<template><a href="/">images here!</a></template>`
    },
    getFileName(string) {
      var name = string.split("/")
      return name[name.length -1]
    },
    formatDate(datestring) {
      var datetime = new Date(datestring)
      //var date = datetime.split('T')[0]
      //var time = datetime.split('T')[1].split('.')[0]
      //var year = date.split('-')[0]
      //var month = date.split('-')[1]
      //var day = date.split('-')[2]
      return datetime.toString()
      //return month + '/' + day + '/' + year + " " + time;
      //return datetime//moment(datetime).format("MMM Do YYYY, h:mm:ss")
    }
  },
  components: {
    videoPlayer
  }

}
</script>

<style scoped>
  .player {
    width: 480px;
  }
  .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  }
</style>
